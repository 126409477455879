<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省'
						clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市'
						clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="62px" class="w-200">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="开始时间:" label-width="80px">
					<el-date-picker v-model="table.where.start_time" type="date" placeholder="搜索开始时间"
						clearable></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间:" label-width="80px" class="mr-20">
					<el-date-picker v-model="table.where.end_time" type="date" placeholder="搜索结束时间"
						clearable></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
					<el-button @click="(table.where = {}) && $refs.table.reload()">重置</el-button>
				</el-form-item>
			</el-form>
			<!-- 大数据统计表 -->

			<div class="my-headline">大数据统计</div>
			<el-row :gutter="20" class="el-row-custom">
				<el-col :span="6">
					<div>单日成交总额(元)</div>
					<div>￥ {{ resules.moneys }}</div>
				</el-col>
				<el-col :span="6">
					<div>累计成交总额(元)</div>
					<div>￥ {{ resules.all_moneys }}</div>
				</el-col>
				<el-col :span="6">
					<div>单日订单成交量(笔)</div>
					<div>{{ resules.order_number }}</div>
				</el-col>
				<el-col :span="6">
					<div>累计订单成交量(笔)</div>
					<div>{{ resules.all_order_number }}</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="el-row-custom">
				<el-col :span="6">
					<div>单日用户入驻量(位)</div>
					<div>{{ resules.user_number }}</div>
				</el-col>
				<el-col :span="6">
					<div>累计用户入驻量(位)</div>
					<div>{{ resules.all_user_number }}</div>
				</el-col>
				<el-col :span="6">
					<div>单日司机入驻量(位)</div>
					<div>{{ resules.drvier_number }}</div>
				</el-col>
				<el-col :span="6">
					<div>累计司机入驻量(位)</div>
					<div>{{ resules.all_drvier_number }}</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" class="el-row-custom">
				<el-col :span="6">
					<div>单日推广量(位)</div>
					<div>{{ resules.invite_number }}</div>
				</el-col>
				<el-col :span="6">
					<div>累计推广量(位)</div>
					<div>{{ resules.all_invite_number }}</div>
				</el-col>
				<el-col :span="6">
					<div>平均诚信度</div>
					<div>{{ resules.avg_credit }}</div>
				</el-col>

			</el-row>

			<el-row :gutter="20" class="el-row-custom">

				<el-col :span="6">
					<div>预约单数量</div>
					<div>{{ resules.subscribe_sum }}</div>
				</el-col>
				<el-col :span="6">
					<div>预约单价格</div>
					<div>￥ {{ resules.subscribe_price }}</div>
				</el-col>
			</el-row>

			<div class="my-headline" style="border: none;margin-top: 20px;padding-bottom: 0px;margin-bottom: 0px;">数据统计
			</div>
			<div id="main" style="width: 100%;height:500px;margin-top:20px"></div>
		</el-card>
	</div>
</template>

<script>
import {
	mapGetters
} from "vuex";

export default {
	name: "SysUser",
	data() {
		return {
			table: {
				where: {}
			},
			choose: [], // 表格选中数据
			editForm: {}, // 表单数据
			checkForm: {},
			provArr: [],
			cityArr: [],
			districtArr: [],
			urgentList: [],
			imageUrl: false,
			headImg: '',
			resules: {},
			date: [],
		}
	},
	created() {
		this.$http.get('/common/province_list').then(res => {
			let data = JSON.parse(res.data)
			this.provArr = data
		})
		const loading = this.$loading({
			lock: true
		});
		this.$http.get('/datas/data', this.table.where).then(res => {
			loading.close()
			this.resules = res.data.result
			this.date = res.data.data
			this.getMap()
		})
	},
	computed: {
		...mapGetters(["permission"]),
	},
	methods: {
		getMap() {
			let that = this
			this.$nextTick(() => {
				let dom = document.getElementById('main')
				let myChart = this.$echarts.init(dom);
				let option;

				option = {

					tooltip: {
						trigger: 'axis',
					},
					grid: {
						top: '24%',
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						left: 'center',
						padding: [20, 0, 20, 0],
						// data: ['当日成交订单数', '用户新增数', '司机新增数', '超市新增数', '跑腿新增数', '餐饮新增数', '当日推广量', '截至目前交易数据', '当日取消订单数','预约单数量','预约单金额'],
						data: [ '用户新增数', '司机新增数', '超市新增数', '跑腿新增数', '餐饮新增数', '当日推广量', '截至目前交易数据', '当日取消订单数','95128电召订单数', '巡游出租订单数', '预约单订单数', '预约单金额'],

					},
					xAxis: [{
						type: 'category',
						data: that.date.date.split(','),
					}],
					yAxis: [
						{
							type: 'value',
							name: '数量',
							min: 0,
							// max: 7000,
							max: function (value) {
								return Math.ceil(value.max);
							},
							interval: 200,
							axisLabel: {
								formatter: '{value}'
							}
						}, {
							type: 'value',
							name: '金额(mb)',
							min: 0,
							// max: 1200,
							// min: function (value) {
							//   return Math.floor( value.min);
							// },
							max: function (value) {
								return Math.ceil(value.max);
							},
							interval: 1000,
							axisLabel: {
								formatter: '{value}'
							}
						},
					],
					series: [
						{
							name: '当日成交订单数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num1.split(',')
						},
						{
							name: '用户新增数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num2.split(',')
						},
						{
							name: '司机新增数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num3.split(',')
						},
						{
							name: '超市新增数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num4.split(',')
						},
						{
							name: '餐饮新增数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num5.split(',')
						},
						{
							name: '跑腿新增数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num6.split(',')
						},
						{
							name: '当日推广量',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num7.split(',')
						},
						{
							name: '截至目前交易数据',
							type: 'bar',
							yAxisIndex: 1,
							data: that.date.num8.split(',')
						},
						{
							name: '当日取消订单数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num9.split(',')
						},
						{
							name: '预约单金额',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num10.split(',')
						},
						{
							name: '预约单订单数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.nums.split(',')
						},
						{
							name: '95128电召订单数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num11.split(',')
						},
						{
							name: '巡游出租订单数',
							type: 'line',
							yAxisIndex: 0,
							data: that.date.num12.split(',')
						},
					]
				};

				option && myChart.setOption(option);
			})
		},
		search() {

			const loading = this.$loading({
				lock: true
			});
			this.$http.post('/datas/data', this.table.where).then(res => {
				loading.close();
				this.resules = res.data.result
				this.date = res.data.data
				this.getMap()

			})
		},
		/**
		 *选择省
		 **/
		handleChangeProv(e) {
			/** 获取被选省份的pid**/
			let pid = ''
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.cityArr = data
				/** 选择省份清空市县**/
				this.table.where.cid = ''
				this.table.where.aid = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
			} else {
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
		},

	}
}
</script>

<style scoped>
.el-row-custom {
	height: 120px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	line-height: 30px;
	border-bottom: rgb(240, 240, 240) 1px solid;
	font-size: 15px;
}
</style>
